import {ReactElement} from "react";
import {GoVipLevelsCardData} from "./goVipText";
import GoVipLevelsSquare from "./GoVipLevelsSquare";
import {useGate} from "statsig-react";

interface Props {
    levelsData: GoVipLevelsCardData
}

function GoVipLevelsModal(props: Props): ReactElement {
    const {value: dpayStatus} = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);

    return (
        <div className="go-vip-levels_body_cards-container_card">
            <GoVipLevelsSquare level={props.levelsData.level}/>

            <div className="go-vip-levels_body_cards-container_card_content">
                <div className="go-vip-levels_body_cards-container_card_content_section">
                    <p className="go-vip-levels_body_cards-container_card_content_section_header">
												Prize
                    </p>
                    <p>
                        <strong>{props.levelsData.prize}</strong>
                    </p>
                </div>

                {dpayStatus &&
										<div className="go-vip-levels_body_cards-container_card_content_section">
										    <p className="go-vip-levels_body_cards-container_card_content_section_header">
										        {import.meta.env.VITE_TOKEN_NAME} Reward
										    </p>
										    <p>
										        <strong>{`$${props.levelsData.level * 2}.00`}</strong>
										    </p>
										</div>
                }

                <div className="go-vip-levels_body_cards-container_card_content_section">
                    <p className="go-vip-levels_body_cards-container_card_content_section_header">
						XP to next level
                    </p>
                    <p>
                        <strong>{props.levelsData.level},000</strong>
                    </p>
                </div>

            </div>
        </div>
    );
}

export default GoVipLevelsModal;
